import React from 'react';
import { Typography, Box, Link as MuiLink, Card, CardContent, Divider, List, ListItem, ListItemText } from '@mui/material';
import AcademiaItem from '../components/AcademiaItem';
import SOMCIS from '../assets/academia/Plataforma_Tecnologica_de_Inteligencia_Artificial.pdf';
import FNNDSC_Slides from '../assets/academia/Symposium_Slides.pdf';
import FNNDSC_Abstract from '../assets/academia/FNNDSC_Symposium_Abstract.pdf';
import FNNDSC_Certificate from '../assets/academia/Symposium_Certificate.pdf';
import highres_abstract from '../assets/academia/highres_abstract.pdf';
import highres_slides from '../assets/academia/highres_slides.pdf';
import AI_Cures_Poster from '../assets/academia/AI_Cures_Poster.jpeg';
import ParticlesBg from 'particles-bg'


const presentations = [
  {
    id: 1,
    authors: "Guillermo Tafoya Milo, Sungmin You, Carlos S. Amador Izaguirre, Milton O. Candela Leal, Hyuk Jin Yun, P Ellen Grant, Kiho Im",
    title: "Gestational Age-Conditioned Anomaly Detection in Fetal Brain MRI",
    eventOrSource: "Presented at the FNNDSC, BCH, Harvard Medical School Symposium, Boston, MA, USA",
    date: "Mar-20 2024",
    slides: FNNDSC_Slides,
    certificate: FNNDSC_Certificate,
    document: FNNDSC_Abstract
  },
  {
    id: 2,
    authors: "Milton Osiel Candela Leal, Marisol Lemus Aguilar, Enrique Mondragon Estrada, Iqui Balam Heredia Marin, Guillermo Tafoya Milo, Jennings Zhang, Hyuk Jin Yun, P. Ellen Grant, Kiho Im",
    title: "High-resolution Fetal Subplate Automatic Segmentation",
    eventOrSource: "Presented at the presented at the FNNDSC, BCH, Harvard Medical School Symposium, Boston, MA, USA",
    date: "Mar-20 2024",
    slides: highres_slides,
    document: highres_abstract
  },
  {
    id: 3,
    authors: "Sungmin You, Guillermo Tafoya Milo, Carlos S. Amador Izaguirre, Seungyoon Jeong, Hyuk Jin Yun, P Ellen Grant, Kiho Im",
    title: "Covariate-conditioned deep generative anomaly detection in fetal brain MRI with ventriculomegaly",
    eventOrSource: "Presented at the MIT AI Cures Conference, Cambridge, MA, USA",
    date: "Apr-1 2024",
    poster: AI_Cures_Poster,
    link: "https://www.mitmgb.ai/posters"
  }
];

const writtenWorks = [
  {
    id: 1,
    authors: "Guillermo Tafoya Milo",
    title: "AI in Early Healthcare: Anticipating Challenges and Implications",
    eventOrSource: "Published at Prof. Alfred Z. Spector's course page: Data Science in Context, 2024",
    link: "https://datascienceincontext.com/ai-in-early-healthcare-anticipating-challenges-and-implications/",
    note: "This paper was written for Alfred Spector’s MIT Spring 2024 course 6.S963 Beyond Models – Applying Data Science/AI Effectively."
  },
  {
    id: 2,
    authors: "Guillermo Tafoya Milo, Jorge Askur Vázquez Fernández, Andrés Sarellano Acevedo, Sebastián Joya Páez, José Miguel Flores González, Leonardo Garrido Luna, Luis Alberto Muñoz Ubando",
    title: "Plataforma Tecnológica de Inteligencia Artificial para el apoyo de tratamiento de personas con Trastorno del Espectro Autista",
    eventOrSource: "Unpublished Manuscript, 2022",
    document: SOMCIS,
  }
];

const Academia = () => {
  return (
    <>
    <Box sx={{ padding: 3, position: 'relative', minHeight: '100vh', overflow: 'hidden' }}>
      {/* Particles Background
      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}>
          <ParticlesBg color="#3f51b5" num={5} type="fountain" bg={true} />
      </Box> */}

      {/* Title */}
      <Typography variant="h4" gutterBottom style={{ marginBottom: '2rem' }}>
        Academia
      </Typography>

      {/* Presentations Section */}
      <Box>
        <Typography variant="h5" gutterBottom>
          Presentations
        </Typography>
        <Divider />
        <List>
          {presentations.map(item => (
            <AcademiaItem key={item.id} {...item} />
          ))}
        </List>
      </Box>

      {/* Written Work Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>
          Written Work
        </Typography>
        <Divider />
        <List>
          {writtenWorks.map(item => (
            <AcademiaItem key={item.id} {...item} />
          ))}
        </List>
      </Box>
    </Box>
    </>
  );
};

export default Academia;