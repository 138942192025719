import React from 'react';
import { Grid, Card, CardContent, Typography, Avatar, Box, Link as MuiLink  } from '@mui/material';
import profilePicture from '../assets/ProfilePicture.jpeg';
import ParticlesBg from 'particles-bg'

const AboutMe = () => {
  return (
    <>
      {/* Container for the entire section */}
      <Box sx={{ position: 'relative', minHeight: '100vh', overflow: 'hidden' }}>
        
        {/* Particles Background */}
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}>
          <ParticlesBg color="#3f51b5" num={50} type="cobweb" bg={true} />
        </Box>

        {/* Content Box */}
        <Box sx={{ position: 'relative', zIndex: 1, marginTop: '2rem', padding: '2rem' }}>
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            
            {/* Profile Picture */}
            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
              <Avatar 
                alt="Guillermo Tafoya" 
                src={profilePicture}
                sx={{ width: 200, height: 200, margin: 'auto' }} 
              />
            </Grid>

            {/* About Me Content */}
            <Grid item xs={12} md={8}>
              {/* Transparent Card */}
              <Card sx={{ padding: '1rem', backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(1px)' }}>
                <CardContent>
                  <Typography variant="h4" component="div" gutterBottom>
                    Hi, I'm Guillermo Tafoya
                  </Typography>
                  <Typography variant="body1">
                    I'm a Computer Science student from <MuiLink href="https://www.tec.mx/en" target="_blank" rel="noopener noreferrer">Tecnológico de Monterrey</MuiLink>.{' '}
                    Passionate about AI, software development, and research, I have experience working inside the <MuiLink href="https://www.fnndsc.org/" target="_blank" rel="noopener noreferrer">FNNDSC</MuiLink> {' '}
                    at <MuiLink href="https://hms.harvard.edu/" target="_blank" rel="noopener noreferrer">Harvard Medical School</MuiLink> {' '}
                    <MuiLink href='https://research.childrenshospital.org/neuroim/people/student-intern-guillermo-tafoya-milo' target="_blank" rel="noopener noreferrer">[member spotlight]</MuiLink>, {' '}
                    <MuiLink href="https://www.infosys.com/" target="_blank" rel="noopener noreferrer">Infosys</MuiLink>, <MuiLink href="https://www.banorte.com/" target="_blank" rel="noopener noreferrer">Banorte</MuiLink>, {' '}
                    <MuiLink href="https://www.cemex.com/" target="_blank" rel="noopener noreferrer">Cemex</MuiLink>, and <MuiLink href="https://www.media.mit.edu/" target="_blank" rel="noopener noreferrer">MIT Media Lab</MuiLink>{' '}
                    <MuiLink href="https://sites.mit.edu/tafoya/" target="_blank" rel="noopener noreferrer">[MIT site]</MuiLink>.{' '}
                    My work spans across natural language processing, deep learning for medical applications, and building full-stack software solutions. I'm constantly driven by the desire to make an impact through technology.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AboutMe;
