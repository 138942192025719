import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css'; // Timeline styles
import { Typography, Box, Link as MuiLink } from '@mui/material'; // Material-UI components
import WorkIcon from '@mui/icons-material/Work';
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';
import './Experience.css'; // Custom styles for animations (optional)

const experiences = [
  {
    title: 'Software Engineer',
    company: 'Amazon',
    date: 'Starting Dec 2024',
    location: 'Mexico City, Mexico',
    description: (
      <ul>
        <li>Enjoying the jungle.</li>
        <li>Amazonian.</li>
      </ul>
    ),
  },
  {
    title: 'Artificial Intelligence Intern',
    company: 'Infosys Limited',
    date: 'July 2024 – Sep 2024',
    location: 'Bangalore, India',
    description: (
      <ul>
        <li>Did some AI for finance.</li>
      </ul>
    ),
  },
  {
    title: 'Undergraduate CS Researcher',
    company: 'MIT Media Lab – FI',
    date: 'Nov 2023 – Present',
    location: 'Cambridge, US',
    icon: <ScienceRoundedIcon />,
    description: (
      <ul>
        <li>
          Collaboration with{' '}
          <MuiLink href="https://nathanww.github.io/" target="_blank" rel="noopener noreferrer">
            Dr. Nathan Whitmore
          </MuiLink>.
        </li>
        <li>
          Researched in a project similar to Men in Black{' '}
          <MuiLink
            href="https://meninblack.fandom.com/wiki/Neuralyzer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Neuralyzer
          </MuiLink>{' '}
          to help people forget bad memories.
        </li>
      </ul>
    ),
  },  
  {
    title: 'Undergraduate AI Researcher',
    company: 'Harvard Medical School',
    date: 'July 2023 – July 2024',
    location: 'Boston, US',
    icon: <ScienceRoundedIcon />,
    description: (
      <ul>
        <li>
          Researched AI for baby brains at the{' '}
          <MuiLink href="https://www.fnndsc.org/" target="_blank" rel="noopener noreferrer">
            FNNDSC
          </MuiLink>.
        </li>
      </ul>
    ),
  },
  {
    title: 'Software Engineering Intern',
    company: 'Banorte Bank',
    date: 'July 2023 – July 2024',
    location: 'Remote',
    description: (
      <ul>
        <li>Did internal bank full-stack software and SQL.</li>
        <li>Made problem tickets be solved faster.</li>
      </ul>
    ),
  },
  {
    title: 'Software Engineering Intern',
    company: 'Cemex',
    date: 'May 2023 – Dec 2023',
    location: 'Remote',
    description: (
      <ul>
        <li>Did RecSys.</li>
        <li>Enjoyed Taco Tuesdays.</li>
      </ul>
    ),
  },
  {
    title: 'Software Product Sprinter',
    company: 'Google',
    date: 'May 2022 – Aug 2022',
    location: 'Remote',
    description: (
      <ul>
        <li>Met cool people.</li>
        <li>Developed a solution for mental health.</li>
      </ul>
    ),
  },
];

const Experience = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom style={{marginBottom: '2rem' }}>
        Experience
      </Typography>
      <VerticalTimeline>
        {experiences.map((exp, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            date={exp.date}
            iconStyle={{ background: exp.background || 'rgb(33, 150, 243)', 
              color: '#fff' }}
            icon={exp.icon || <WorkIcon />}
          >
            {/* Position and Location in the same row */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" component="h3">
                {exp.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {exp.location}
              </Typography>
            </Box>

            {/* Company Name Below */}
            <Typography
              variant="subtitle1"
              component="p"
              style={{ fontStyle: 'italic', marginTop: '0.5rem' }}
            >
              {exp.company}
            </Typography>

            {/* Description */}
            <Typography variant="body1" component="div">
              {exp.description}
            </Typography>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </Box>
  );
};

export default Experience;
