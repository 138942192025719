import React from 'react';
import { Card, CardMedia, CardContent, Typography, Button, CardActions, Box } from '@mui/material';

const ProjectCard = ({ title, description, image, onLearnMore, outline }) => {
  return (
    <Card
      sx={{
        maxWidth: 350,
        minHeight: 345,
        display: 'flex',           // Make the Card a flex container
        flexDirection: 'column',   // Column layout (stack content vertically)
        justifyContent: 'space-between', // Ensure content and actions are spaced
        // Glowing light blue outline if exp.outline
        boxShadow: outline ? '0 0 10px 5px rgba(31,150,243, 0.1)' : 'none',
      }}
      onClick={onLearnMore}
    >
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt={title}
      />
      <Box sx={{ flexGrow: 1 }}>  {/* This ensures CardContent grows to take available space */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Box>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button size="small" color="primary" onClick={onLearnMore}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProjectCard;
